.join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.left-j {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.left-j > hr {
  position: absolute;
  width: 10.5rem;
  color: greenyellow;
  border: 2px solid #ffff00;
  margin: -10px;
  border-radius: 30%;
}
.right-j {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.email-container {
  display: flex;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem 2rem;
  box-shadow: 0 0 3px 1px #000;
}
.email-container input {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  padding: 1rem;
  outline: none;
  color: yellow;
}
::placeholder {
  color: yellow;
}
@media screen and (max-width: 768px) {
  .join {
    flex-direction: column;
    gap: 1rem;
  }
  .left-j {
    font-size: x-large;
    flex-direction: column;
  }
  .right-j {
    padding: 2rem;
  }
  .email-container {
    flex-direction: column;
  }
}
