.plans-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.plans-container > div:nth-child(1) {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}
.plans-container > div:nth-child(2) {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}
.plans {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.plan {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.plan:nth-child(2) {
  transform: scale(1.1);
  background-color: rgba(43, 252, 0, 0.6);
}

.plan > :nth-child(1) {
  fill: var(--orange);
  align-self: center;
  width: 2rem;
  height: 2rem;
}
.plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}

.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.plan > :nth-child(5) {
  font-size: 0.8rem;
  cursor: pointer;
}
.features {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.feature img {
  width: 1rem;
}
.plan:nth-child(2) > svg {
  fill: black;
}
.plan:nth-child(2) > button {
  color: #fff;
}
.plan:nth-child(2) > button:hover {
  color: #110;
}
@media screen and (max-width: 768px) {
  .plans {
    flex-direction: column;
    gap: 1rem;
  }
  .plans > :nth-child(2) {
    transform: none;
  }
}
